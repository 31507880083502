[data-elm-hot="true"] {
  height: inherit;
}

body {
  font-family: 'Source Sans Pro', 'Trebuchet MS', 'Lucida Grande', 'Bitstream Vera Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  text-align: center;
  color: #293c4b;
}

h1 {
  font-size: 30px;
}

img {
  margin: 20px 0;
  max-width: 200px;
}
